body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.half-shadow {
  
}
.half-shadow::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 50%; /* Half of the original height */
  top: 0;
  left:0;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) ;
  z-index: -1;
}
.grey-900 {
  background-color: #212121;
}

#introCarousel,
      .carousel-inner,
      .carousel-item,
      .carousel-item.active {
        height: 100vh;
      }

      .carousel-item {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
      }


      /* Height for devices larger than 576px */
      @media (min-width: 992px) {
        #introCarousel {
          margin-top: -58.59px;
        }
        .resp-carousel {
          height: 350px;
        }
      }
      @media (max-width: 375px) {
        .resp-carousel {
          height: 170px;
        }
      }